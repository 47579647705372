import React from "react";
import SubHead from "./SubHead";
import NavLink from "./NavLink";

const HelicopterModels = ({ settings, data, location }) => (
  <SubHead settings={settings}>
    <ul>
      {data.map(({ node: model }) => (
        <NavLink
          key={model.id}
          current={location.pathname}
          path={`/sales/helicopters/${model.frontmatter.make}/${model.frontmatter.title}`}
        >
          {model.frontmatter.make} {model.frontmatter.title}
        </NavLink>
      ))}
    </ul>
    <br />
    <ul>
      <NavLink
        current={location.pathname}
        path="/sales/helicopters/other/other"
      >
        » See Other Models
      </NavLink>
      <NavLink
        current={location.pathname}
        path="/sales/helicopters/for_sale"
        className="more"
      >
        » See All Aircraft
      </NavLink>
    </ul>
  </SubHead>
);

export default HelicopterModels;
