import React, { Component } from "react";
import { graphql } from "gatsby";
import SalesWrap from "../../components/SalesWrap";
import "./sales.css";

class LearnPage extends Component {
  constructor() {
    super();
    this.state = { open: {} };
    this.showMore = this.showMore.bind(this);
  }

  showMore(event, model) {
    event.preventDefault();
    this.setState(prevState => ({
      open: { ...prevState.open, [model]: true }
    }));
  }

  render() {
    const { data } = this.props;
    return (
      <SalesWrap {...this.props}>
        {data.allMarkdownRemark.edges.map(({ node: model }) => (
          <div className="sale_items" key={model.id}>
            <div className="sale_item">
              <img
                src={`/images/new_${model.frontmatter.title.toLowerCase()}.jpg`}
                alt={`${model.frontmatter.title} Helicopter`}
                className="new_model float_left"
              />
              <h3>
                {model.frontmatter.make} {model.frontmatter.title}
              </h3>

              <p>{model.frontmatter.summary}</p>

              {this.state.open[model.frontmatter.title] ? (
                <div className="new_model_description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: model.html
                    }}
                  />

                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={model.frontmatter.info}
                  >
                    Read more about the {model.frontmatter.make}{" "}
                    {model.frontmatter.title} at Schweizer's site.
                  </a>
                </div>
              ) : (
                <button
                  className="readmore"
                  onClick={event =>
                    this.showMore(event, model.frontmatter.title)
                  }
                >
                  Read more
                </button>
              )}
            </div>
          </div>
        ))}
      </SalesWrap>
    );
  }
}

export default LearnPage;

export const query = graphql`
  query SalesLearnModelsQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { showcase: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            make
            info
            summary
          }
          html
        }
      }
    }
  }
`;
