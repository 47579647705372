import React, { Component } from "react";
import MainWrap from "./MainWrap";
import HelicopterModels from "./HelicopterModels";

const SETTINGS = {
  name: "Sales",
  img1: "sales.jpg",
  subhead: "Helicopter Sales",
  subheadIndex: 3,
  subheadArrow: "Schweizer Aircraft Corp.",
  title: "Helicopter Sales from Bijan Air, Inc.",
  heading: "Helicopter Sales from Bijan Air, Inc."
};

class SalesWrap extends Component {
  render() {
    const { data, settings, location = {} } = this.props;
    let customSettings = { ...SETTINGS, ...settings };

    const Subheader = props => (
      <HelicopterModels
        {...this.props}
        settings={customSettings}
        data={data.allMarkdownRemark.edges}
      />
    );

    return (
      <MainWrap
        settings={customSettings}
        Subheader={Subheader}
        location={location}
      >
        {this.props.children}
      </MainWrap>
    );
  }
}

export default SalesWrap;
